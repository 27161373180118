<template>
  <section class="reward-modal-view">
    <b-loading :active.sync="isLoading" :is-full-page="false"></b-loading>

    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Dados do pagamento</p>
        <button type="button" class="delete" @click="$emit('close')" />
      </header>
      <section class="modal-card-body">
        <app-input disable label="Recebedor" :value="payment.referrer.name" />

        <app-input
          disable
          label="Total de conversões"
          :value="payment.total_conversions"
        />

        <app-input
          disable
          label="Valor a pagar"
          :value="payment.total_amount | currency('BRL')"
        />

        <app-input
          disable
          label="Status da transação"
          :value="payment.payment_status === 1 ? 'Pago' : 'A pagar'"
        />

        <app-input disable label="Chave PIX" :value="payment.referrer.cpf" />

        <app-file-picker
          v-if="payment.payment_status !== 1"
          label="Comprovante de pagamento"
          accept="image/jpeg,image/png,image/jpg,application/pdf"
          v-model="payment_proof"
          :errors="errors.payment_proof"
          @fileDeleted="() => (payment_proof = null)"
          :onlyFile="true"
        >
        </app-file-picker>
        <div v-else class="payment_proof">
          <p class="label">Comprovante de pagamento</p>

          <b-button
            @click="onPaymentProofDownloadClick(payment.payment_proof)"
            type="is-secondary"
            expanded
          >
            Baixar comprovante
          </b-button>
        </div>
      </section>
      <footer class="modal-card-foot is-right">
        <b-button
          @click="onSubmit"
          :disabled="!payment_proof"
          v-if="payment.payment_status !== 1"
          type="is-secondary"
          expanded
        >
          Confirmar pagamento
        </b-button>
      </footer>
    </div>
  </section>
</template>

<script>
import ReferralProgramService from "../../../services/referral-program.service";
export default {
  props: {
    payment: Object,
  },
  data: () => ({
    isLoading: false,
    payment_proof: null,
    errors: {},
  }),
  methods: {
    onSubmit() {
      this.$buefy.dialog.confirm({
        title: "Confirmação de pagamento",
        message:
          "Tem certeza de que deseja <b>confirmar</b> este pagamento? Esta ação não poderá ser desfeita.",
        confirmText: "Confirmar",
        type: "is-warning",
        hasIcon: true,
        onConfirm: () => this.markAsPaid(),
      });
    },
    onPaymentProofDownloadClick(url) {
      window.open(url, "_blank");
    },
    markAsPaid() {
      this.isLoading = true;

      ReferralProgramService.markPaymentAsPaid(this.payment.id, {
        payment_proof: this.payment_proof,
      })
        .then(() => {
          this.$buefy.snackbar.open("Pagamento confirmado com sucesso!");
          this.$emit("close", true);
        })
        .catch((response) => {
          const { data, status } = response;

          if (status === 422) {
            this.errors = data.errors;
          } else
            this.$buefy.snackbar.open("Erro ao confirmar pagamento.", "error");
        })
        .finally(() => (this.isLoading = false));
    },
  },
};
</script>

<style lang="scss">
.field .label,
.app-file-picker .label,
.payment_proof .label {
  font-weight: bold;
  color: grey !important;
  font-family: "Cabin";
}

.payment_proof .image {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: auto;

  img {
    max-height: auto;
    max-width: 100%;
  }
}
</style>
