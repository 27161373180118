<template>
  <section class="referral-program-view">
    <app-header icon="trophy" title="Indique e ganhe"></app-header>

    <div class="referral-program-view__payments mt-4">
      <PaymentsList />
    </div>

    <div v-if="!isFinancialUser" class="referral-program-view__referrers mt-4">
      <ReferrersList />
    </div>

    <div v-if="!isFinancialUser" class="referral-program-view__rewards mt-4">
      <RewardsList />
    </div>
  </section>
</template>

<script>
import RewardsList from "./Rewards/RewardsList.vue";
import ReferrersList from "./Referrers/ReferrersList.vue";
import PaymentsList from "./Payments/PaymentsList.vue";
import { mapGetters } from "vuex";

export default {
  components: { RewardsList, ReferrersList, PaymentsList },
  computed: {
    ...mapGetters(['isFinancialUser'])
  }
};
</script>

<style></style>
